
import { defineComponent } from "vue";
import { MdButton } from "@/components/md/MdButton";
import { MdProgressIndicatorCircular } from "../MdProgressIndicator";
import { TransitionFade } from "@/components/cc/Transition";
import TransitionMdOverlay from "./TransitionMdOverlay.vue";

export default defineComponent({
  name: "MdOverlay",
  components: {
    TransitionMdOverlay,
    MdButton,
    MdProgressIndicatorCircular,
    TransitionFade,
  },
  props: {
    title: String,
    image: String,
    large: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      visible: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
      document.documentElement.style.overflow = "hidden";
    },
    close() {
      this.visible = false;
      document.documentElement.style.overflow = "auto";
    },
    setLoading(value: boolean) {
      this.loading = value;
    },
  },
});
