
import { defineComponent } from "vue";
import { MdCard } from "@/components/md/MdCard";
import OverviewChatbotCard from "@/components/admin/ChatbotCard.vue";
import OverviewChatbotLoader from "@/components/admin/ChatbotCardLoader.vue";
import HeaderImageLeft from "@/assets/images/overview-header-left.svg";
import HeaderImageRight from "@/assets/images/overview-header-right.svg";
import { getAllBots, IChatbotResult } from "@/api/bot";
import ChatbotCreateOverlay from "@/components/bot/ChatbotCreateOverlay.vue";
import { IMdOverlay } from "@/components/md/MdOverlay";

export default defineComponent({
  name: "Overview",
  components: {
    MdCard,
    OverviewChatbotCard,
    OverviewChatbotLoader,
    ChatbotCreateOverlay,
  },
  created() {
    this.loadChatbots();
  },
  data() {
    return {
      chatbots: [] as IChatbotResult[],
      HeaderImageLeft,
      HeaderImageRight,
    };
  },
  computed: {
    createChatbotOverlay(): IMdOverlay {
      return this.$refs.createChatbotOverlay as IMdOverlay;
    },
  },
  methods: {
    loadChatbots() {
      getAllBots().then((bots) => {
        this.chatbots = bots;
      });
    },
    redirectToChatbot(chatbot: IChatbotResult) {
      this.$router.push({
        path: "/bot/" + chatbot.id,
      });
    },
    openCreateChatbotOverlay() {
      this.createChatbotOverlay.open();
    },
  },
});
