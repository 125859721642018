
import { defineComponent } from "vue";
import { MdOverlay, MdOverlayComponentMixin } from "@/components/md/MdOverlay";
import { IMdInput, MdInputField } from "@/components/md/MdInput";
import { MdButton } from "@/components/md/MdButton";
import { IStepper, Step, Stepper } from "@/components/cc/Stepper";
import { MdCard } from "@/components/md/MdCard";
import { useStore } from "@/store";
import { RootStore } from "@/store/constants";
import { createChatbot } from "@/api/bot/create";

export default defineComponent({
  name: "ChatbotCreateOverlay",
  mixins: [MdOverlayComponentMixin],
  components: {
    MdOverlay,
    MdInputField,
    MdButton,
    Stepper,
    Step,
    MdCard,
  },
  emits: ["close"],
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    stepper(): IStepper {
      return this.$refs.stepper as IStepper;
    },
    nameInput(): IMdInput {
      return this.$refs.nameInput as IMdInput;
    },
  },
  methods: {
    onStepperChange(step: number) {
      this.step = step + 1;
    },
    goToNextStep() {
      if (this.step == 1) {
        if (this.chatbotName.length < 3) {
          this.nameInput.setErrorMessage(
            "Gebe einen Namen mit mindestens 3 Zeichen ein"
          );
          return false;
        } else {
          this.nameInput.setErrorMessage("");
          this.stepper.next();
        }
      } else {
        this.createChatbot();
      }
    },
    async createChatbot() {
      this.setLoading(true);
      try {
        await createChatbot(this.chatbotName);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "success",
          content: "Chatbot wurde erstellt",
        });
      } catch (err) {
        console.log("error: ", err);
        this.store.commit(RootStore.Mutations.SET_SNACKBAR, {
          state: "error",
          content: "Chatbot konnte nicht erstellt werden",
        });
      }
      this.$emit("close");
      this.setLoading(false);
      this.close();
    },
  },
  data() {
    return {
      chatbotName: "",
      step: 1,
    };
  },
});
